<template>
  <page-layout :show-back-button="false">
    <span slot="header-name">{{ $tf('appearance') }}</span>
    <el-form slot="content" :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('language')">
        <el-select v-model="$i18n.locale" size="mini" @change="localeHandler">
          <el-option key="ru" value="ru" label="Русский"></el-option>
          <el-option key="en" value="en" label="English"></el-option>
          <el-option :key="item.name" :value="item.name" :label="item.label" v-for="item in $store.getters.additionalLanguages"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/page/layout';

export default {
  name: 'page-appearance',
  components: { PageLayout },
  methods: {
    localeHandler(v) {
      window.localStorage.locale = v;
      this.$store.state.app.acceptLanguage = this.$t('accept_language');
    }
  }
};
</script>
